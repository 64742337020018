<template>
  <v-row style="height: 100vh;">
    <v-col cols="6" lg="6" xl="6" class="warning d-none d-md-flex align-center justify-center">
      <v-container >
        <div class="pa-10">
          <v-row justify="center">
            <v-col cols="8" xl="5">
              <div>
                <h2 class="display-1 white--text font-weight-medium">
                  {{main_fm_title}}
                </h2>
                <h6 class="subtitle-1 mt-4 white--text op-5 font-weight-regular">
                  {{ main_fm_subtitle }}
                </h6>
                <hr/>
                <br>
                <p class="text--accent-1">
                  {{ help_cta }}
                  <b>help@farmania.it</b>
                </p>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
    <v-col cols="12" lg="5" xl="6" class="d-flex align-center">
      <v-container>
        <div class="pa-7 pa-sm-12">
          <v-row>
            <v-col cols="12" lg="9" xl="6">
              <!--              <img src="@/assets/images/logo-icon.png" />-->
              <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">Accedi</h2>
              <h6 class="subtitle-1">

                <v-spacer></v-spacer>
              </h6>

              <v-form ref="form" v-model="valid" lazy-validation action="/dashboards/analytical">
                <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="E-mail"
                    class="mt-4"
                    required
                    outlined
                ></v-text-field>
                <v-text-field
                    v-model="password"
                    label="Password"
                    required
                    outlined
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (show1 = !show1)"
                    :type="show1 ? 'text' : 'password'"
                ></v-text-field>

                <div class="d-block d-sm-flex align-center mb-4 mb-sm-0">
                  <div class="ml-auto">
                    <!--                    <a href="javascript:void(0)" class="link">Forgot pwd?</a>-->
                  </div>
                </div>
                <v-btn
                    :disabled="!valid"
                    color="info"
                    block
                    class="mr-4"
                    submit
                    @click="submit"
                >Accedi</v-btn>
              </v-form>

              <v-col>
                <v-btn
                    outlined
                    color="success"
                    block
                    class="mr-4"
                    @click="recover_access">
                  Ricupera credenziali

                </v-btn>
              </v-col>

              <div class="text-center mt-6">
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>

import { auth } from "@/firebase";

export default {
  name: "FullLogin",
  data: () => ({
    valid: true,
    password: "",
    show1: false,
    email: "",
    main_fm_title: 'Farmania Gestione Prodotti',
    main_fm_subtitle: 'Sviluppiamo Business per le farmacie.',
    help_cta: 'Hai bisogno di aiuto? Scrivi a:',
    emailRules: [
      v => !!v || "E-mail richiesta",
      v => /.+@.+\..+/.test(v) || "E-mail deve essere valida"
    ],
  }),
  methods: {
    submit() {
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        this.onSignIn();
      }
    },
    onSignIn () {
      this.$store.dispatch('signUserIn',
          {
            email: this.email,
            password: this.password
          })
    },

    recover_string() {
      return  {
        isShown: true,
        title: 'Attenzione',
        message: 'Se la e-mail inserita é valida, riceverai le istruzioni per ripristinare.',
        type: 'success',
      }
    },
    error_string(error) {
      return  {
        isShown: true,
        title: 'Attenzione',
        message: error,
        type: 'error',
      }
    },
    recover_access() {
      auth.sendPasswordResetEmail(this.email)
          .then(function () {
            this.$Simplert.open(this.recover_string());
          }.bind(this))
          .catch(error => {
            this.$Simplert.open(this.error_string(error.toLocaleString()));
      })
    },

    onDismiss () {
      this.$store.dispatch('clearError')
    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    },
    error () {
      return this.$store.getters.error
    },
    loading () {
      return this.$store.getters.loading
    }
  },
  watch: {
    user (value) {
      if (value !== null && value !== undefined) {
        this.$router.push({ path: "/pharmacies/singleProductInput" });
      }
    }
  }
};
</script>
